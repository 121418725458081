.onboarding-mobile {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .onboarding-mobile .div-2 {
    background-color: #ffffff;
    border: 0px none;
    height: 852px;
    overflow: hidden;
    position: relative;
    width: 393px;
  }
  
  .onboarding-mobile .text-wrapper {
    color: #000000;
    font-family: var(--heading-1-mobile-font-family);
    font-size: var(--heading-1-mobile-font-size);
    font-style: var(--heading-1-mobile-font-style);
    font-weight: var(--heading-1-mobile-font-weight);
    left: 57px;
    letter-spacing: var(--heading-1-mobile-letter-spacing);
    line-height: var(--heading-1-mobile-line-height);
    position: absolute;
    text-align: center;
    top: 94px;
    width: 263px;
  }
  
  .onboarding-mobile .merci-de-rentrer {
    color: var(--neutraldark-grey);
    font-family: var(--body-1-font-family);
    font-size: var(--body-1-font-size);
    font-style: var(--body-1-font-style);
    font-weight: var(--body-1-font-weight);
    left: 56px;
    letter-spacing: var(--body-1-letter-spacing);
    line-height: var(--body-1-line-height);
    position: absolute;
    text-align: center;
    top: 170px;
    width: 280px;
  }
  
  .onboarding-mobile .group {
    height: 82px;
    left: 56px;
    position: absolute;
    top: 750px;
    width: 284px;
  }
  
  .onboarding-mobile .vous-n-avez-plus-de {
    color: var(--neutraldark-grey);
    font-family: var(--CP-light-font-family);
    font-size: var(--CP-light-font-size);
    font-style: var(--CP-light-font-style);
    font-weight: var(--CP-light-font-weight);
    left: 10px;
    letter-spacing: var(--CP-light-letter-spacing);
    line-height: var(--CP-light-line-height);
    position: absolute;
    text-align: center;
    top: 0;
    width: 261px;
  }
  
  .onboarding-mobile .text-wrapper-2 {
    color: var(--primary-1);
    font-family: var(--button-2-link-font-family);
    font-size: var(--button-2-link-font-size);
    font-style: var(--button-2-link-font-style);
    font-weight: var(--button-2-link-font-weight);
    left: 0;
    letter-spacing: var(--button-2-link-letter-spacing);
    line-height: var(--button-2-link-line-height);
    position: absolute;
    text-align: center;
    text-decoration: underline;
    top: 61px;
    white-space: nowrap;
    width: 280px;
  }
  
  .onboarding-mobile .button-action-instance {
    left: 17px !important;
    position: absolute !important;
    top: 354px !important;
    width: 341px !important;
  }
  
  .button-action-instance.onboarding{
    left: 17px !important;
  }
  
  .onboarding-mobile .keyboard {
    -webkit-backdrop-filter: blur(108.73px) brightness(100%);
    backdrop-filter: blur(108.73px) brightness(100%);
    background-color: #d1d5db;
    height: 291px;
    left: 9px;
    position: absolute;
    top: 859px;
    width: 375px;
  }
  
  .onboarding-mobile .overlap {
    height: 283px;
    left: 1px;
    position: relative;
    top: 8px;
  }
  
  .onboarding-mobile .home-indicator-instance {
    left: 0 !important;
    position: absolute !important;
    top: 249px !important;
  }
  
  .onboarding-mobile .keys {
    height: 259px;
    left: 2px;
    position: absolute;
    top: 0;
    width: 369px;
  }
  
  .onboarding-mobile .dictation {
    height: 25px;
    left: -1904px;
    position: absolute;
    top: -2531px;
    width: 15px;
  }
  
  .onboarding-mobile .emoji {
    height: 27px;
    left: -2209px;
    position: absolute;
    top: -2532px;
    width: 27px;
  }
  
  .onboarding-mobile .overlap-group {
    height: 42px;
    left: -2234px;
    position: absolute;
    top: -2772px;
    width: 182px;
  }
  
  .onboarding-mobile .return {
    background-image: url(../../../static/img/shift.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 42px;
    left: 0;
    position: absolute;
    top: 0;
    width: 88px;
  }
  
  .onboarding-mobile .label {
    color: #000000;
    font-family: "SF Pro Text-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 0;
    letter-spacing: -0.32px;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 10px;
    white-space: nowrap;
    width: 88px;
  }
  
  .onboarding-mobile .space {
    background-image: url(../../../static/img/shift.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 42px;
    left: 0;
    position: absolute;
    top: 0;
    width: 182px;
  }
  
  .onboarding-mobile .label-2 {
    color: #000000;
    font-family: "SF Pro Text-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 0;
    letter-spacing: -0.32px;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 10px;
    white-space: nowrap;
    width: 182px;
  }
  
  .onboarding-mobile .label-wrapper {
    background-image: url(../../../static/img/shift.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 42px;
    left: 0;
    position: absolute;
    top: 0;
    width: 87px;
  }
  
  .onboarding-mobile .label-3 {
    color: #000000;
    font-family: "SF Pro Text-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 0;
    letter-spacing: -0.32px;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 10px;
    white-space: nowrap;
    width: 87px;
  }
  
  .onboarding-mobile .delete {
    height: 42px;
    left: 327px;
    position: absolute;
    top: 108px;
    width: 42px;
  }
  
  .onboarding-mobile .key-light {
    background-image: url(../../../static/img/shift.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 42px;
    left: -2561px;
    position: absolute;
    top: -2880px;
    width: 42px;
  }
  
  .onboarding-mobile .label-4 {
    color: #000000;
    font-family: "SF Pro Text-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 0;
    letter-spacing: -0.32px;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 10px;
    white-space: nowrap;
    width: 42px;
  }
  
  .onboarding-mobile .delete-button {
    height: 17px;
    left: -2221px;
    position: absolute;
    top: -2751px;
    width: 23px;
  }
  
  .onboarding-mobile .shift {
    height: 42px;
    left: 0;
    position: absolute;
    top: 108px;
    width: 42px;
  }
  
  .onboarding-mobile .overlap-group-wrapper {
    height: 42px;
    left: 0;
    position: absolute;
    top: 0;
    width: 42px;
  }
  
  .onboarding-mobile .symbol-wrapper {
    background-color: #fcfcfe;
    border-radius: 4.6px;
    box-shadow: 0px 1px 0px #898a8d;
    height: 44px;
    left: -1px;
    position: relative;
    top: -1px;
    width: 44px;
  }
  
  .onboarding-mobile .symbol {
    color: #000000;
    font-family: "SF Pro Text-Regular", Helvetica;
    font-size: 22.5px;
    font-weight: 400;
    left: 21px;
    letter-spacing: -0.55px;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 8px;
    white-space: nowrap;
  }
  
  .onboarding-mobile .img {
    height: 16px;
    left: -2219px;
    position: absolute;
    top: -2752px;
    width: 19px;
  }
  
  .onboarding-mobile .m {
    height: 42px;
    left: 282px;
    position: absolute;
    top: 108px;
    width: 32px;
  }
  
  .onboarding-mobile .div-wrapper {
    background-color: #fcfcfe;
    border-radius: 4.6px;
    box-shadow: 0px 1px 0px #898a8d;
    height: 44px;
    left: -1px;
    position: relative;
    top: -1px;
    width: 34px;
  }
  
  .onboarding-mobile .symbol-2 {
    color: #000000;
    font-family: "SF Pro Text-Regular", Helvetica;
    font-size: 22.5px;
    font-weight: 400;
    left: 7px;
    letter-spacing: -0.55px;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 8px;
    white-space: nowrap;
  }
  
  .onboarding-mobile .n {
    height: 42px;
    left: 244px;
    position: absolute;
    top: 108px;
    width: 32px;
  }
  
  .onboarding-mobile .symbol-3 {
    color: #000000;
    font-family: "SF Pro Text-Regular", Helvetica;
    font-size: 22.5px;
    font-weight: 400;
    left: 9px;
    letter-spacing: -0.55px;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 8px;
    white-space: nowrap;
  }
  
  .onboarding-mobile .b {
    height: 42px;
    left: 206px;
    position: absolute;
    top: 108px;
    width: 32px;
  }
  
  .onboarding-mobile .symbol-4 {
    color: #000000;
    font-family: "SF Pro Text-Regular", Helvetica;
    font-size: 22.5px;
    font-weight: 400;
    left: 10px;
    letter-spacing: -0.55px;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 8px;
    white-space: nowrap;
  }
  
  .onboarding-mobile .v {
    height: 42px;
    left: 169px;
    position: absolute;
    top: 108px;
    width: 32px;
  }
  
  .onboarding-mobile .c {
    height: 42px;
    left: 131px;
    position: absolute;
    top: 108px;
    width: 32px;
  }
  
  .onboarding-mobile .x {
    height: 42px;
    left: 94px;
    position: absolute;
    top: 108px;
    width: 32px;
  }
  
  .onboarding-mobile .z {
    height: 42px;
    left: 56px;
    position: absolute;
    top: 108px;
    width: 32px;
  }
  
  .onboarding-mobile .l {
    height: 42px;
    left: 319px;
    position: absolute;
    top: 54px;
    width: 32px;
  }
  
  .onboarding-mobile .symbol-5 {
    color: #000000;
    font-family: "SF Pro Text-Regular", Helvetica;
    font-size: 22.5px;
    font-weight: 400;
    left: 11px;
    letter-spacing: -0.55px;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 8px;
    white-space: nowrap;
  }
  
  .onboarding-mobile .k {
    height: 42px;
    left: 281px;
    position: absolute;
    top: 54px;
    width: 32px;
  }
  
  .onboarding-mobile .j {
    height: 42px;
    left: 244px;
    position: absolute;
    top: 54px;
    width: 32px;
  }
  
  .onboarding-mobile .h {
    height: 42px;
    left: 206px;
    position: absolute;
    top: 54px;
    width: 32px;
  }
  
  .onboarding-mobile .g {
    height: 42px;
    left: 169px;
    position: absolute;
    top: 54px;
    width: 32px;
  }
  
  .onboarding-mobile .f {
    height: 42px;
    left: 131px;
    position: absolute;
    top: 54px;
    width: 32px;
  }
  
  .onboarding-mobile .d {
    height: 42px;
    left: 94px;
    position: absolute;
    top: 54px;
    width: 32px;
  }
  
  .onboarding-mobile .s {
    height: 42px;
    left: 56px;
    position: absolute;
    top: 54px;
    width: 32px;
  }
  
  .onboarding-mobile .a {
    height: 42px;
    left: 19px;
    position: absolute;
    top: 54px;
    width: 32px;
  }
  
  .onboarding-mobile .p {
    height: 42px;
    left: 337px;
    position: absolute;
    top: 0;
    width: 32px;
  }
  
  .onboarding-mobile .o {
    height: 42px;
    left: 300px;
    position: absolute;
    top: 0;
    width: 32px;
  }
  
  .onboarding-mobile .symbol-6 {
    color: #000000;
    font-family: "SF Pro Text-Regular", Helvetica;
    font-size: 22.5px;
    font-weight: 400;
    left: 8px;
    letter-spacing: -0.55px;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 8px;
    white-space: nowrap;
  }
  
  .onboarding-mobile .i {
    height: 42px;
    left: 262px;
    position: absolute;
    top: 0;
    width: 32px;
  }
  
  .onboarding-mobile .symbol-7 {
    color: #000000;
    font-family: "SF Pro Text-Regular", Helvetica;
    font-size: 22.5px;
    font-weight: 400;
    left: 14px;
    letter-spacing: -0.55px;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 8px;
    white-space: nowrap;
  }
  
  .onboarding-mobile .u {
    height: 42px;
    left: 225px;
    position: absolute;
    top: 0;
    width: 32px;
  }
  
  .onboarding-mobile .y {
    height: 42px;
    left: 187px;
    position: absolute;
    top: 0;
    width: 32px;
  }
  
  .onboarding-mobile .t {
    height: 42px;
    left: 150px;
    position: absolute;
    top: 0;
    width: 32px;
  }
  
  .onboarding-mobile .r {
    height: 42px;
    left: 112px;
    position: absolute;
    top: 0;
    width: 32px;
  }
  
  .onboarding-mobile .e {
    height: 42px;
    left: 75px;
    position: absolute;
    top: 0;
    width: 32px;
  }
  
  .onboarding-mobile .w {
    height: 42px;
    left: 37px;
    position: absolute;
    top: 0;
    width: 32px;
  }
  
  .onboarding-mobile .symbol-8 {
    color: #000000;
    font-family: "SF Pro Text-Regular", Helvetica;
    font-size: 22.5px;
    font-weight: 400;
    left: 6px;
    letter-spacing: -0.55px;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 8px;
    white-space: nowrap;
  }
  
  .onboarding-mobile .q {
    height: 42px;
    left: 0;
    position: absolute;
    top: 0;
    width: 32px;
  }
  
  .onboarding-mobile .text-field-code-number {
    left: 41px !important;
    position: absolute !important;
    top: 259px !important;
  }
  
  .onboarding-mobile .header-mobile-instance {
    left: 0 !important;
    position: fixed !important;
    top: 0 !important;
  }
  
  .error-message{
    position: absolute;
      top: 317px;
      margin-left: 100px;
      color: red;
  }
  
  .onboarding{
    cursor: pointer;
  }
  
  .loader{
    margin-top: 311px !important;
    margin-left: 165px !important;
  }
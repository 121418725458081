.footer-patient {
    background: white;
    padding: 30px;
    font-size: 12px
}

.footer-patient .footer-cgu{
    color: #4282ff;
}

.footer-patient .support{
    text-align: center;
}

.footer-cgu{
    list-style: none;
    display: inline-flex;
    padding: 0px;
}

.footer-cgu li{
    margin-right: 10px;
}
@media (min-width: 770px){
    .footer.patient{
        margin: auto;
        width: 416px;
    }
}